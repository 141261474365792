import * as PasswordValidator from './passwordValidator';

function checkEmailExists(email) {
    const endpoint = `/api/method/zoni_edu.zoni_edu.doctype.account_activation.account_activation.check_email_exists`;

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "X-Frappe-CSRF-Token": frappe.csrf_token
        },
        body: JSON.stringify({ email: email })
    })
    .then(response => response.json())
    .then(data => {
        return data.message && data.message.exists;
    })
    .catch(error => {
        console.error('Error:', error);
        return false; // Treat errors as non-existing email to allow process continuation
    });
}

function validateActivationCode(email, phone, activationCode) {
    const validationUrl = '/api/method/zoni_edu.zoni_edu.doctype.account_activation.account_activation.validate_activation_code';

    return fetch(validationUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "X-Frappe-CSRF-Token": frappe.csrf_token
        },
        body: JSON.stringify({ email: email, phone: phone, activation_code: activationCode })
    })
    .then(response => response.json())
    .then(data => {
        return data; // Return the entire response for further processing
    })
    .catch(error => {
        console.error('Error:', error);
        return { status: 'error', message: 'An error occurred while validating the activation code.' };
    });
}

function otpInputFieldHandling(email, phone) {
    let in1 = document.getElementById('otc-1'),
        ins = document.querySelectorAll('input[type="number"]'),
        splitNumber = function(e) {
            let data = e.data || e.target.value;
            if (!data) return;
            if (data.length === 1) return;

            popuNext(e.target, data);
        },
        popuNext = function(el, data) {
            el.value = data[0];
            data = data.substring(1);
            if (el.nextElementSibling && data.length) {
                popuNext(el.nextElementSibling, data);
            }
        };

    ins.forEach(function(input, index) {
        input.addEventListener('keyup', async function(e) {
            if ([16, 9, 224, 18, 17].includes(e.keyCode)) {
                return;
            }

            if ((e.keyCode === 8 || e.keyCode === 37) && this.previousElementSibling && this.previousElementSibling.tagName === "INPUT") {
                this.previousElementSibling.select();
            } else if (e.keyCode !== 8 && this.nextElementSibling) {
                this.nextElementSibling.select();
            }

            if (e.target.value.length > 1) {
                splitNumber(e);
            }

            if (index === ins.length - 1 && e.target.value) {
                await handleOtpComplete(email, phone, ins);
            }
        });

        input.addEventListener('input', async function(e) {
            // Detect when all fields are filled, including auto-fill
            if (Array.from(ins).every(input => input.value)) {
                await handleOtpComplete(email, phone, ins);
            }
        });

        input.addEventListener('paste', async function(e) {
            let pasteData = e.clipboardData.getData('text');
            if (pasteData.length === ins.length) {
                Array.from(ins).forEach((input, i) => {
                    input.value = pasteData[i];
                });

                await handleOtpComplete(email, phone, ins);
            }
        });

        input.addEventListener('focus', function(e) {
            if (this === in1) return;

            if (in1.value == '') {
                in1.focus();
            }

            if (this.previousElementSibling && this.previousElementSibling.value == '') {
                this.previousElementSibling.focus();
            }
        });
    });

    in1.addEventListener('input', splitNumber);
}

async function handleOtpComplete(email, phone, inputs) {
    const activationCode = Array.from(inputs).map(input => input.value).join('');

    const validationResponse = await validateActivationCode(email, phone, activationCode);

    if (validationResponse.message.status === 'success') {
        // Hide all open modals
        var openModals = document.querySelectorAll('.modal.show');
        openModals.forEach(modal => {
            var bootstrapModal = bootstrap.Modal.getInstance(modal);
            bootstrapModal.hide();
        });

        if (!PasswordValidator.isInitialized) {
            PasswordValidator.initializePasswordValidator(email, phone, activationCode);
        }
    } else {
        // Show the error message
        document.getElementById('account-activation-verification-error').textContent = validationResponse.message.message;
        document.getElementById('account-activation-verification-error').style.display = 'block';
    }
}


export async function showCodeValidatorModal(email, phone) {
    console.log("American High School Enrollment");
    // show modal to request user 6 digit activation code

    if (!email || !phone) {
        console.log('Email and phone number are required to request the activation code.');
        return;
    }

    const emailCheck = await checkEmailExists(email);

    if (emailCheck) {
        console.log('Email already exists!');
        // Add an "OK" button to the modal
        const okButton = $('<button>').addClass('btn btn-primary').text('OK');
        okButton.attr('onclick', '$("#alertModal").modal("hide");');
        $('#alertModalContent').html(''); // Clear any previous content
        $('#alertModalContent').append(okButton);
        $('#alertModal').find('.modal-body').text('Email already exists! We have sent to your email address the login procedure. If you still have issues please contact us.');
        $('#alertModal').modal('show');
        return;
    }

    const modalElement = document.getElementById('verificationModal');
    const bootstrapModal = new bootstrap.Modal(modalElement);

    if (!modalElement || !bootstrap.Modal) {
        console.log("Modal or Bootstrap is not available.");
        return;
    }

    // Initialize OTP input handling
    otpInputFieldHandling(email, phone);

    // Scroll modal into view and focus on the first input element
    modalElement.addEventListener('shown.bs.modal', () => {
        // Use a manual scroll override with scrollY
        const rect = modalElement.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop; // Use scrollY instead of pageYOffset
        const offsetTop = rect.top + scrollTop;

        // Scroll the window to the top of the modal
        console.log("Scrolling to top of modal...");
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });

        // Focus on the first input field after scrolling
        document.getElementById('otc-1').focus();
    });

    // Show the modal
    bootstrapModal.show();
}
