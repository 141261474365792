import * as SessionManagement from './sessionManagement';
export let isInitialized = false;

export function initializePasswordValidator(email, phone, activation_code) {
    console.log('Initializing password validator');
    if (isInitialized) {
        console.warn('Password validator already initialized');
        return;
    }

    const form = document.getElementById("resetPasswordForm");
    if (!form) return;

    const passwordResetModal = new bootstrap.Modal(document.getElementById('resetPasswordModal'));

    const passwordInput1 = document.getElementById("new-password");
    const passwordInput2 = document.getElementById("confirm-new-password");
    const submitButton = document.getElementById("resetSubmitButton");
    const submitSpinner = document.getElementById('resetPasswordModalLoadingSpinner');

    const togglePassword1 = document.getElementById("new-password-toggle");
    const togglePassword2 = document.getElementById("confirm-new-password-toggle");

    const backendFeedbackElement = document.getElementById('set-password-backend-feedback');

    if (!passwordInput1 || !passwordInput2 || !submitButton || !togglePassword1 || !togglePassword2 || !backendFeedbackElement) {
        return;
    }

    submitButton.disabled = true;
    let isSubmitting = false;

    async function checkLogInStatus() {
        const loginStatus = await SessionManagement.getLoginStatus();
        if (loginStatus.message.logged_in) {
            await SessionManagement.logoutUser();   // Logout the user if logged in
        }
    }

    checkLogInStatus();

    togglePassword1.addEventListener('click', function() {
        togglePasswordVisibility(passwordInput1, this);
    });

    togglePassword2.addEventListener('click', function() {
        togglePasswordVisibility(passwordInput2, this);
    });

    const checkPassword = async function() {
        let ucase = new RegExp("[A-Z]+");
        let lcase = new RegExp("[a-z]+");
        let num = new RegExp("[0-9]+");

        let pass1 = passwordInput1.value;
        let pass2 = passwordInput2.value;

        let checks = [
            updateStatus("new-8char", pass1.length >= 8),
            updateStatus("new-ucase", ucase.test(pass1)),
            updateStatus("new-lcase", lcase.test(pass1)),
            updateStatus("new-num", num.test(pass1)),
            updateStatus("new-pwmatch", pass1 === pass2)
        ];

        if (checks.every(Boolean)) {
            const backendValidation = await validatePasswordBackend(pass1);
            checks.push(updateStatus("new-backend", backendValidation.message.meets_requirements));
            updateBackendFeedback(backendValidation.message);
        }

        submitButton.disabled = !checks.every(Boolean);
    };

    const updateStatus = function(id, condition) {
        const element = document.getElementById(id);
        if (condition) {
            element.classList.remove("fa-times-circle");
            element.classList.add("fa-check-circle");
            element.style.color = "#78981fFF"; // Lighter green
            return true;
        } else {
            element.classList.remove("fa-check-circle");
            element.classList.add("fa-times-circle");
            element.style.color = "#ef3a437f"; // Lighter red
            return false;
        }
    };

    const validatePasswordBackend = async function(password) {
        const endpointUrl = '/api/method/zoni_edu.zoni_edu.doctype.account_activation.account_activation.validate_password_strength';

        try {
            const response = await fetch(`${endpointUrl}?password=${encodeURIComponent(password)}`);
            return await response.json();
        } catch (error) {
            console.error('Error validating password:', error);
            return { meets_requirements: false, feedback: {} };
        }
    };

    const updateBackendFeedback = function(message) {
        backendFeedbackElement.innerHTML = '';

        if (message.feedback.warning) {
            backendFeedbackElement.innerHTML += `<small>${message.feedback.warning}</small><br>`;
        }

        if (message.feedback.suggestions) {
            message.feedback.suggestions.forEach(suggestion => {
                backendFeedbackElement.innerHTML += `<small>${suggestion}</small><br>`;
            });
        }
    };

    passwordInput1.addEventListener("keyup", checkPassword);
    passwordInput2.addEventListener("keyup", checkPassword);

    form.addEventListener('submit', async function(event) {
        event.preventDefault(); // Prevent default form submission

        if (isSubmitting) return;

        isSubmitting = true;

        // Disable the submit button to prevent multiple submissions
        submitButton.disabled = true;

        // Show the loading spinner
        const submitText = submitButton.textContent;
        submitSpinner.classList.remove('d-none');
        submitButton.textContent = ' ... ';
        submitButton.prepend(submitSpinner);  // Keep spinner visible after text change

        const response = await setPassword(email, phone, activation_code);

        if (response && response.message && response.message.status === 'success' && response.message.redirect) {
            // Pass the email and new password to the showModal function for logging in
            await showModal(response.message.message, true, response.message.redirect, email, passwordInput1.value);
        } else {
            showModal(response?.message?.message || 'Failed to reset password');
        }

        // Re-enable the submit button and hide the spinner
        submitSpinner.classList.add('d-none');
        submitButton.textContent = submitText;
        submitButton.disabled = false;

        isSubmitting = false;
    });

    async function setPassword(email, phone, activation_code) {
        const newPassword = passwordInput1.value;
        const confirmNewPassword = passwordInput2.value;

        const endpointUrl = '/api/method/zoni_edu.zoni_edu.doctype.account_activation.account_activation.activate_account';

        try {
            const response = await fetch(endpointUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "X-Frappe-CSRF-Token": frappe.csrf_token
                },
                body: JSON.stringify({
                    email: email,
                    phone: phone,
                    activation_code: activation_code,
                    password: newPassword,
                    confirm_password: confirmNewPassword
                })
            });
            return await response.json();
        } catch (error) {
            console.error('Error:', error);
            showModal('Failed to reset password');
            return null;
        }
    }

    async function showModal(message, isSuccess = false, redirectUrl = '', email = '', password = '') {
        // Hide all open modals
        var openModals = document.querySelectorAll('.modal.show');
        openModals.forEach(modal => {
            var bootstrapModal = bootstrap.Modal.getInstance(modal);
            bootstrapModal.hide();
        });

        $('#alertModalContent').html(message + '<br><br>');

        const okButton = $('<button>').addClass('btn btn-primary').text('OK');
        okButton.attr('onclick', '$("#alertModal").modal("hide");');
        $('#alertModalContent').append(okButton);

        $('#alertModal').modal('show');

        if (isSuccess && redirectUrl) {
            $('#alertModal').on('hidden.bs.modal', async function () {
                // Log in the user before redirecting
                const loginSuccessful = await SessionManagement.loginUser(email, password);
                if (loginSuccessful) {
                    window.location.href = redirectUrl;
                } else {
                    // If login fails, show an error message or handle accordingly
                    console.error('Redirect failed due to unsuccessful login.');
                    $('#alertModalContent').html('Failed to log in. Please try again.');
                    $('#alertModal').modal('show');
                }
            });
        }
    }

    // Scroll modal into view and focus on the new-password input field
    const modalElement = document.getElementById('resetPasswordModal');
    const bootstrapModal = new bootstrap.Modal(modalElement);

    if (!modalElement || !bootstrap.Modal) {
        console.log("Modal or Bootstrap is not available.");
        return;
    }

    modalElement.addEventListener('shown.bs.modal', () => {
        console.log('Scrolling into view');
        // Use a timeout to fix scrollIntoView issue on Safari and iOS
        setTimeout(() => {
            modalElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
            document.getElementById('new-password').focus();
        }, 10);  // Small delay to ensure the element is fully ready
    });

    bootstrapModal.show();
    isInitialized = true;
}

function togglePasswordVisibility(passwordInput, toggleButton) {
    const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordInput.setAttribute('type', type);
    toggleButton.innerHTML = type === 'password' ? '<i class="fas fa-eye-slash" aria-hidden="true"></i>' : '<i class="fas fa-eye" aria-hidden="true"></i>';
}
